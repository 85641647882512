import NextImage from 'next/image'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import { Icon, Tag } from 'components/ui'
import { ButtonLink } from 'components/ui/deprecated/ButtonLink'
import { Text } from 'components/ui/deprecated/Text'
import { useTranslation } from 'lib/i18n'
import { addAlphaToColor } from 'lib/style'
import { getStoryblokImageAttributes } from 'lib/utils/content'
import { removeLeadingAndTrailingSlash } from 'lib/utils/string'

import { convertDistance } from '../../../../lib/utils/convertDistance'

type Props = {
  name: string
  image: string | null
  href: string
  distance: number | null
  country: string | null
  address: string
  phone: string | null
  onSelect: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
}

export const ClinicLocatorThumb = ({
  name,
  image,
  href,
  distance,
  country,
  address,
  phone,
  onSelect,
  onMouseEnter,
  onMouseLeave,
  ...props
}: Props): JSX.Element => {
  const {
    query: { lang },
  } = useRouter()
  const [, region] = ((lang as string) || '').split('-')
  const { i18n } = useTranslation()

  const url = href.startsWith('http')
    ? href
    : `/${removeLeadingAndTrailingSlash(href)}`

  return (
    <Item {...props} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ImageHolder>
        <MapButtonHolder role="button" onClick={() => onSelect()}>
          <Icon icon="map-pointer" />
          <Text as="span" variant="twelve">
            {i18n('clinicLocator.map')}
          </Text>
        </MapButtonHolder>
        {image && (
          <NextImage
            src={
              getStoryblokImageAttributes(
                { filename: image },
                '400x400/smart/filters:format(webp)'
              ).src
            }
            style={{ objectFit: 'cover', height: '100%' }}
            alt={name}
            fill
          />
        )}
      </ImageHolder>
      <Content>
        <ContentTop>
          {(country || distance) && (
            <TopHolder>
              {country && (
                <Text as="p" variant="fourteen">
                  {country}
                </Text>
              )}
              {distance && <Tag>{convertDistance(distance, region)}</Tag>}
            </TopHolder>
          )}
          <TitleText as="h2" variant="title/small/regular">
            {name}
          </TitleText>
          <Text as="p">{address}</Text>
        </ContentTop>
        <ContentFooter>
          {address && (
            <ButtonLink
              variant="text"
              size="micro"
              href={`https://maps.google.com?daddr=${encodeURIComponent(
                address
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon icon="directions" />
              {i18n('clinicLocator.directions')}
            </ButtonLink>
          )}
          {phone && (
            <ButtonLink variant="text" size="micro" href={`tel:${phone}`}>
              <Icon icon="phone" />
              {phone}
            </ButtonLink>
          )}
          <CtaButton
            href={url}
            leftIcon="external"
            size="small"
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n('visitClinic')}
          </CtaButton>
        </ContentFooter>
      </Content>
    </Item>
  )
}

const Item = styled.div`
  padding: 1rem;

  display: flex;

  color: inherit;
  background-color: ${({ theme }) => theme.colors.background.subtle};

  border-radius: 0.75rem;
  overflow: hidden;

  box-shadow: ${({ theme }) => theme.shadows.subtle};
`

const ImageHolder = styled.div`
  display: none;

  position: relative;
  width: 10rem;

  border-radius: 0.25rem;
  overflow: hidden;

  flex-shrink: 0;

  background-color: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 5)};

  ${({ theme }) => theme.media.md} {
    display: block;
  }
`

const MapButtonHolder = styled.div`
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: block;

    position: absolute;
    z-index: ${({ theme }) => theme.zIndex.high};
    left: 0.5rem;
    top: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: white;
    box-shadow: ${({ theme }) => theme.shadows.subtle};
    border-radius: 999px;

    padding-left: 0.25rem;
    padding-right: 0.4rem;
    height: 1.5rem;

    cursor: pointer;
  }
`

const Content = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  ${({ theme }) => theme.media.md} {
    margin-left: 1.5rem;
  }
`

const ContentTop = styled.div`
  width: 100%;
`

const TopHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  margin-bottom: 0.5rem;
`

const ContentFooter = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-auto-flow: dense;
  justify-items: flex-start;

  width: 100%;

  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid
    ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 10)};

  ${({ theme }) => theme.media.md} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
`

const TitleText = styled(Text)`
  margin-bottom: 0.5rem;
`

const CtaButton = styled(ButtonLink)`
  margin-top: 0.75rem;
  width: 100%;

  ${({ theme }) => theme.media.md} {
    margin-top: 0;
    width: auto;
  }
`
